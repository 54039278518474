import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import MobileSideBar from './MobileSideBar';
import menu_arrow_icon from '../../../../assets/icons/menu_arrow_icon.svg';
import masterplan_logo_icon from '../../../../assets/images/masterplan_logo_icon.svg';

interface SubMenuProps {
  isOpen: boolean;
}

const Sidebar = styled.div`
  position: sticky;
  top: calc(80px + 2rem);
  overflow-y: scroll;
  padding-right: 1rem;
  border: none;
  font-size: 14px;
  font-weight: 300;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
  }
  ::-webkit-scrollbar-thumb {
    background-color: #e5e1e1;
    border-radius: 10px;
  }
  @media (max-width: 768px) {
    width: 100vw;
    padding-right: 0;
    height: fit-content;
  }
`;

const Menu = styled.div`
  margin-bottom: 3rem;
`;

const MenuItem = styled.div`
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    font-weight: 500;
    cursor: pointer;
  }
`;

const MenuItemText = styled.div``;

const MenuItemArrow = styled.div<SubMenuProps>`
  background-image: url(${menu_arrow_icon});
  background-size: contain;
  background-repeat: no-repeat;
  width: 10px;
  height: 10px;
  margin-top: 3px;
  transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : 'rotate(0)')};
  transition: all 0.2s ease-in-out;
`;

const SubMenu = styled.div<SubMenuProps>`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  ${MenuItem} {
    width: 170px;
    margin-left: 1rem;
    padding-left: 1rem;
    border-left: 2px solid #e1dfd9;
    &:hover {
      border-left: 2px solid #d5b343;
    }
  }
`;

const AppIcon = styled.img`
  width: 60px;
  margin-bottom: 2rem;
`;

interface SideBarProps {
  scrollToElement: any;
}

const SideBar = ({ scrollToElement }: SideBarProps) => {
  const [activePage, setActivePage] = useState<any>(null);
  const [isFeaturesSubmenu, setIsFeaturesSubmenu] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const handleMenuClick = (event: any, element: string) => {
    scrollToElement(element);
    setIsFeaturesSubmenu(false);
    if (!event.target.classList.value.includes('active')) {
      event.target.classList.toggle('active');
      if (activePage) activePage.classList.remove('active');
      setActivePage(event.target);
    }
  };

  const handleSubMenuClick = (event: any, element: string) => {
    scrollToElement(element);
    if (!event.target.classList.value.includes('active')) {
      event.target.classList.toggle('active');
      if (activePage) activePage.classList.remove('active');
      setActivePage(event.target);
    }
  };

  return (
    <Sidebar>
      {isMobile ? (
        <MobileSideBar handleMenuClick={handleMenuClick} />
      ) : (
        <Menu>
          <AppIcon src={masterplan_logo_icon} />
          <MenuItem
            className="menu-item"
            onClick={(event) => {
              handleMenuClick(event, 'installation');
            }}
          >
            Installation
          </MenuItem>
          <MenuItem
            className="menu-item"
            onClick={(event) => {
              handleMenuClick(event, 'get-started');
            }}
          >
            Get started
          </MenuItem>
          <MenuItem
            className="menu-item"
            onClick={() => setIsFeaturesSubmenu((prevState) => !prevState)}
          >
            <MenuItemText>Features</MenuItemText>
            <MenuItemArrow isOpen={isFeaturesSubmenu} />
          </MenuItem>
          <SubMenu isOpen={isFeaturesSubmenu}>
            <MenuItem
              className="submenu-item"
              onClick={(event) => handleSubMenuClick(event, 'create-event')}
            >
              Create Event
            </MenuItem>
            <MenuItem
              className="submenu-item"
              onClick={(event) => handleSubMenuClick(event, 'reschedule-event')}
            >
              Reschedule Event
            </MenuItem>
            <MenuItem
              className="submenu-item"
              onClick={(event) =>
                handleSubMenuClick(event, 'change-event-duration')
              }
            >
              Change Event Duration
            </MenuItem>
            <MenuItem
              className="submenu-item"
              onClick={(event) => handleSubMenuClick(event, 'duplicate-event')}
            >
              Duplicate Event
            </MenuItem>
            <MenuItem
              className="submenu-item"
              onClick={(event) => handleSubMenuClick(event, 'delete-event')}
            >
              Delete Event
            </MenuItem>
            <MenuItem
              className="submenu-item"
              onClick={(event) => handleSubMenuClick(event, 'views')}
            >
              Views
            </MenuItem>
            <MenuItem
              className="submenu-item"
              onClick={(event) => handleSubMenuClick(event, 'connect-subitems')}
            >
              Connect Subitems
            </MenuItem>
            <MenuItem
              className="submenu-item"
              onClick={(event) => handleSubMenuClick(event, 'export-calendar')}
            >
              Export Calendar
            </MenuItem>
          </SubMenu>
          <MenuItem
            className="menu-item"
            onClick={(event) => {
              handleMenuClick(event, 'optimization');
            }}
          >
            Optimization
          </MenuItem>
        </Menu>
      )}
    </Sidebar>
  );
};

export default SideBar;
